// import { parseStr } from '~/share/utils'
/*
Web 文案
1.默认标题：text id=28144(Get 5% off your 1st app booking)
2.默认描述：text id=28145(Booking's better on the app. Use promo code "BetterOnApp" to save!)
3. 12998 - CTA: Go to App
4. 77653 - 二维码上方说明：Scan the magic code to download our app

Mweb文案
1. 74646 - Description: Get 5% off your 1st app booking with code BetterOnApp!
2. 12998 - CTA: Go to app
*/
export const textInfo: any = {
  desktop: {
    qrCodeTip: '77653',
    title: '28144',
    desc: ['28145'],
    downloadText: '12998'
  },
  mobile: {
    desc: ['74646'],
    downloadText: '12998'
  }
}

export const cmsidHandler = (val: any, $t: Function) => {
  if (typeof val === 'string') {
    return $t(val)
  } else {
    return Object.keys(val).reduce((obj: any, key: any) => {
      const v = val[key]
      obj[key] = cmsidHandler(v, $t)
      return obj
    }, Array.isArray(val) ? [] : {})
  }
}

export const getPageSpm2meta = (meta: any) => {
  const spmArr = ['Experience_Vertical', 'Experience_SubVertical', 'Experience_SearchResult', 'Experiences_MFO']
  const metaArr = ['trackedPageName', 'gaPageName']
  const arr = metaArr.map((key: string) => {
    return meta[key]
  }).filter((spm: string) => {
    return spmArr.includes(spm)
  })
  return arr[0]
}

export const getOTAData = (pageKey = '', _$t: Function, _options: any) => {
  let data = {}
  // const opt = options || {}
  switch (pageKey) {
    case 'mobile-activity-page':
      data = {
        type: 'topFixed',
        location: 'ta_activity_top',
        animate: 'transform' // transform子层级弹窗会受影响，padding会影响cls值
        // infoData: {
        //   tracking: {
        //     close: { spm: 'LargeDownloadAppBannerCloseBtn' },
        //     module: { spm: 'LargeDownloadAppBanner' },
        //     download: { spm: 'LargeDownloadAppBanner' }
        //   }
        // }
      }
      break
    case 'mobile-vertical-page-transform':
      data = {
        // type: 'topFixed',
        location: 'ta_vertical_homepage_top',
        isCloseMini: true,
        animate: 'transform' // transform子层级弹窗会受影响，padding会影响cls值
      }
      break
    case 'mobile-vertical-page':
      data = {
        // type: 'topFixed',
        location: 'ta_vertical_homepage_top',
        isCloseMini: true,
        animate: 'padding' // transform子层级弹窗会受影响，padding会影响cls值
        // infoData: {
        //   tracking: {
        //     close: { spm: 'LargeDownloadAppBannerCloseBtn' },
        //     module: { spm: 'LargeDownloadAppBanner' },
        //     download: { spm: 'LargeDownloadAppBanner' }
        //   }
        // }
      }
      break
    case 'desktop-activity-page':
      data = {
        // type: 'medium',
        location: 'ta_activity_medium',
        animate: 'padding', // transform子层级弹窗会受影响，padding会影响cls值
        downloadText: ''
        // infoData: {
        //   tracking: {
        //     close: { spm: 'FloatingDownloadAppBannerCloseBtn' },
        //     module: { spm: 'FloatingDownloadAppBanner' },
        //     download: { spm: 'FloatingDownloadAppBanner' }
        //   }
        // }
      }
      break
    case 'desktop-vertical-page': // abtest跑输，未使用
      data = {
        // type: 'bottomFixed',
        location: 'ta_vertical_homepage_medium',
        animate: 'padding', // transform子层级弹窗会受影响，padding会影响cls值
        downloadText: ''
        // infoData: {
        //   tracking: {
        //     close: { spm: 'BottomDownloadAppBannerCloseBtn' },
        //     module: { spm: 'BottomDownloadAppBanner' },
        //     download: { spm: 'BottomDownloadAppBanner' }
        //   }
        // }
      }
      break
    default:
      break
  }
  return data
}
