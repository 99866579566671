import { Component, Vue, Getter, State } from 'nuxt-property-decorator'
import initCookieManage from '@klook/cookie-manage'
import { Options } from './types'
import cookieManageText from '~/share/pack-text-obj/cookie-manage'
// import { getIsAttractionsRoute } from '~/share/utils.ts'

const isBool = (value: any): value is boolean => typeof value === 'boolean'

@Component
export default class LayoutControl extends Vue {
  @State klook!: Data.Klook
  @Getter isEUArea!: boolean
  @Getter platform!: string

  // 控制 header、footer 的显示
  // 有些页面可能不需要显示他们
  header: boolean = true
  footer: boolean = true
  fixedNav: boolean = true // fixed 导航
  fixedSecondaryNav: boolean = false // fixed 二级导航
  hideSecondaryNav: boolean = false // hide 二级导航

  get calcOpenHarmonyHidden() {
    const { name = '' } = this.$route
    const filterList = ['ActivityHome'] // 活动页
    if (filterList.includes(name)) {
      return false
    }
    const { klook } = this
    return klook.isOpenHarmony
  }

  created() {
    this.setLayout()
    this.onLayout()
  }

  get isWhiteLabelEnv() {
    return !!this.klook?.utilConfig?.isWhiteLabelEnv
  }

  beforeMount() {
    window.$sentry = this.$sentry
    window.Cookies = this.$cookies
    window.$axios = this.$axios
    window.tracker = {
      gtm: {
        sendGTMCustomEvent: this.$sendGTMCustomEvent.bind(this)
      },
      sendMixpanel: () => {},
      inhouse: this.$inhouse
    }
  }

  mounted() {
    initCookieManage(
      cookieManageText(
        (key: string, ...args: any[]) => this.$t(key, ...args),
        (pathname: string, language?: Data.Language, baseLink?: string) => this.$href(pathname, language, baseLink),
        this.isWhiteLabelEnv,
        this.klook.language
      ),
      {
        platform: this.platform,
        isEUArea: this.isEUArea
      }
    ).then((popInstance) => {
      if (popInstance) {
        this.$once('hook:beforeDestroy', () => {
          popInstance.close()
        })
      }
    })
  }

  /**
   * 设置布局
   * 可以在页面组件中通过 meta 控制 header、footer，例如：
   * {
   *   header: false,
   *   footer: false
   * }
   */
  setLayout() {
    const meta = Object.assign({}, this.$route.meta) as Options

    isBool(meta.header) && (this.header = meta.header)
    // isBool(meta.fixedSecondaryNav) && (this.fixedSecondaryNav = meta.fixedSecondaryNav)
    // isBool(meta.hideSecondaryNav) && (this.hideSecondaryNav = meta.hideSecondaryNav)
    isBool(meta.fixedNav) && (this.fixedNav = meta.fixedNav)
    isBool(meta.footer) && (this.footer = meta.footer)
  }

  /**
   * 监听布局事件
   * 可以在页面组件中通过 this.$klook.$emit 来控制 header、footer，例如：
   * this.$klook.$emit('LAYOUT', {
   *   header: false,
   *   footer: false
   * })
   */
  onLayout() {
    this.$klook.$once('LAYOUT', (options: Options) => {
      isBool(options.header) && (this.header = options.header)
      isBool(options.footer) && (this.footer = options.footer)
    })
  }
}
