import { Vue, Component, State, namespace } from 'nuxt-property-decorator'
import { siteConfig } from '@klook/site-config'
import apis from '~/share/data/apis'
import { availableWebsite } from '~/share/data/website-config'
import { queryStringify } from '~/share/utils'

const authModule = namespace('auth')

@Component
export default class changeWebsiteTip extends Vue {
  @State klook!: Data.Klook
  @authModule.State user!: Data.User

  get isWhiteLabelEnv() {
    return !!this.klook?.utilConfig?.isWhiteLabelEnv
  }

  isShowTip = false

  mounted() {
    if (availableWebsite.includes(this.klook.websiteConfig.website) && !this.klook.isKlookApp) {
      this.initShowTip()
    }
  }

  initShowTip() {
    const pages = ['ActivityHome', 'Home']
    if (!localStorage.getItem('changeWebSiteTip') && pages.includes(this.$route.name || '')) {
      const existUserResidence = (this.user && this.user.confirmed_residence) || ''
      let userResidenceToSite = ''
      const { cn, global } = this.websiteLinks
      if (existUserResidence) {
        userResidenceToSite = existUserResidence === 'CN' ? cn : global
      }
      const existCacheSite = this.$cookies.get('website')
      const existCountrySite = this.klook.ipCountry === 'CN' ? cn : global

      const vaildUserPreferSite = userResidenceToSite && this.klook.websiteConfig.website === userResidenceToSite
      const vaildCacheSite = existCacheSite && this.klook.websiteConfig.website === existCacheSite
      const vaildipCountrySite = existCountrySite && this.klook.websiteConfig.website === existCountrySite

      if (userResidenceToSite) {
        if (vaildUserPreferSite) {
          this.isShowTip = false
        } else {
          this.isShowTip = true
          localStorage.setItem('changeWebSiteTip', 'true')
        }
      } else if (existCacheSite && process.env.APP_ENV !== 'production') {
        if (vaildCacheSite) {
          this.isShowTip = false
        } else {
          this.isShowTip = true
          localStorage.setItem('changeWebSiteTip', 'true')
        }
      } else if (existCountrySite) {
        if (vaildipCountrySite) {
          this.isShowTip = false
        } else {
          this.isShowTip = true
          localStorage.setItem('changeWebSiteTip', 'true')
        }
      }
    }
  }

  closeTip() {
    this.isShowTip = false
  }

  siteChange(website: string, language: string) {
    const query = queryStringify({ website, language })
    location.href = this.$href(`/change-website-form?${query}`)
  }

  changeCnWebsite() {
    const { cn } = this.websiteLinks
    const cnSite = {
      title: 'CN',
      value: `https://${cn}`,
      site: cn,
      language: 'zh-CN'
    }

    this.changUserpreferSite(cnSite.site)
    this.siteChange(cnSite.value, cnSite.language)
    this.$sendGTMCustomEvent('Switch Site Pop-up|Click To Switch The Country/Region')
  }

  async changUserpreferSite(site: string) {
    const qs = `site=${site}`
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
    await this.$axios.$post(apis.changePreferSite, qs, { headers })
  }

  get websiteLinks() {
    return siteConfig.generateOtherLinks(this.klook.websiteConfig.website)
  }

  get wantToSite() {
    const website = this.klook.websiteConfig.website
    const { cn, global } = this.websiteLinks
    return website === global ? cn : global
  }

  handleSiteChange() {
    const { global } = this.websiteLinks
    if (this.klook.websiteConfig.website === global) {
      this.changeCnWebsite()
    } else {
      window.location.href = this.$href('/edit_profile?residence=show')
    }
  }
}
